import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Content, { HTMLContent } from '../components/Content'
import SEO from '../components/seo'

const LandingPageTemplate = ({
  title,
  subtitle,
  content,
  contentComponent,
  image,
}) => {
  const PageContent = contentComponent || Content
  return (
    <div>
      <SEO title={title} />
      <Hero title={title} subtitle={subtitle} img={image} />
      <section className="section">
        <div className="container">
          <PageContent className="content" content={content} />
        </div>
      </section>
    </div>
  )
}

LandingPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const LandingPage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout>
      <LandingPageTemplate
        contentComponent={HTMLContent}
        title={page.frontmatter.title}
        subtitle={page.frontmatter.subtitle}
        content={page.html}
      />
    </Layout>
  )
}

LandingPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default LandingPage

export const landingPageQuery = graphql`
  query LandingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
      }
    }
  }
`
